import { Controller } from "@hotwired/stimulus"

let offerInfoCache = {}
function getOfferInfo(offer_id) {
  if (!offer_id) {
    return Promise.reject(new Error('offer_id is required'))
  }
  const ENDPOINT_URL = `/api/v1/offers/${offer_id}.json`

  if (offerInfoCache[offer_id]) {
    return Promise.resolve(offerInfoCache[offer_id])
  }

  return fetch(ENDPOINT_URL)
    .then((res) => res.json())
    .then((json) => {
      console.log(json)
      if (json.status == 'success') {
        offerInfoCache[offer_id] = {
          mainvisual: json['mainvisual_url'],
          categoryname: json['category_name'],
          title: json['title'],
          salarytype: json['salary_type'],
          salaryrange: json['salary_range'],
          holiday: json['holiday_html'],
          address: json['address'],
          brandlogo: json['brand_logo_url'],
          shopname: json['shop_name'],
        }
        return offerInfoCache[offer_id]
      } else {
        throw new Error(json)
      }
    });
}

export default class extends Controller {
  static targets = [
    'container',
    'mainvisual',
    'categoryname',
    'title',
    'salarytype',
    'salaryrange',
    'holiday',
    'address',
    'brandlogo',
    'shopname',
  ];

  static values = {
    offerid: String,
  };

  connect() {
    const offer_id = this.offeridValue
    // console.log(offer_id)
    if (offer_id) {
      this.containerTarget.style.display = 'block'
      getOfferInfo(offer_id)
        .then((json) => {
          this.mainvisualTarget.src = json['mainvisual']
          this.categorynameTarget.innerHTML = json['categoryname']
          this.titleTarget.innerHTML = json['title']
          this.salarytypeTarget.innerHTML = json['salarytype']
          this.salaryrangeTarget.innerHTML = json['salaryrange']
          this.holidayTarget.innerHTML = json['holiday']
          this.addressTarget.innerHTML = json['address']
          this.brandlogoTarget.src = json['brandlogo']
          this.shopnameTarget.innerHTML = json['shopname']
        })
    } else {
      // console.log('offer_id is required')
      this.containerTarget.style.display = 'none'
    }
  }

  offeridValueChanged() {
    this.connect()
  }
}
