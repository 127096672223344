const getCsrfToken = () => {
  const metas = document.getElementsByTagName('meta');
  for (let meta of metas) {
    if (meta.getAttribute('name') === 'csrf-token') {
      return meta.getAttribute('content');
    }
  }
  return '';
}

function isBlank(str) {
  return (!str || /^\s*$/.test(str));
}

function isPresent(str) {
  return !isBlank(str);
}

export {
  getCsrfToken,
  isBlank,
  isPresent
}
