import {Controller} from "@hotwired/stimulus"
import { getCsrfToken } from "./lib/utils"

export default class extends Controller {
  static targets = [
    'templates',
    'template',
  ]

  static values = {
    id: String,
    templates: Array,
  }

  // 最初の一回だけ呼ばれる
  connect() {
    this.fetchTemplate()
  }

  // 検索キーワードが入力された時
  handleOnTemplateSearch(event) {
    const value = event.target.value

    // otherwise, show only matching
    this._renderTemplateList(this.templatesValue.filter(({template_title}) => template_title.includes(value)))
  }

  // テンプレートが選択された時
  handleOnTemplateSelect(event) {
    event.preventDefault()
    const target = event.target.closest('button')
    const id = target.dataset["id"]
    const template = this.templatesValue.find(template => String(template.id) === id)
    this._toScoutTemplateForm(template)
  }

  // unedit-scout-template-form からも呼ばれる
  fetchTemplate() {
    const ENDPOINT_URL = `/manage/scouts/templates.json`
    const requestBody = {
      method: "GET",
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCsrfToken()
      },
    }
    return fetch(ENDPOINT_URL, requestBody)
      .then((res) => res.json())
      .then((json) => {
        this.templatesValue = json
        this._renderTemplateList(this.templatesValue)
      });
  }

  // テンプレート一覧の表示形式
  _templatesListFormat(template) {
    return `
                <li class="c-block-11__list-item" data-unedit-scout-template-modal-target="template" data-name="${template.template_title}">
                  <button type="button" class="c-block-11__list-btn" data-action="click->unedit-scout-template-modal#handleOnTemplateSelect" data-id="${template.id}">
                    <span>${template.template_title}</span>
                  </button>
                </li>`
  }

  // テンプレートフォームに値をセット
  _toScoutTemplateForm(template) {
    const $templateForm = this._templateForm()

    // テンプレートフォームに値をセット
    this.application.getControllerForElementAndIdentifier($templateForm, 'unedit-scout-template-form').setTemplateValues(
      template.id,
      template.template_title,
      template.sender,
      template.title,
      template.content
    )
  }

  // テンプレートフォームのDOMを取得
  _templateForm() {
    return document.getElementById(`unedit_scout_template_form`)
  }

  // テンプレート一覧を描画
  _renderTemplateList(templates) {
    this.templatesTarget.innerHTML = ""
    templates.map(template => {
      this.templatesTarget.insertAdjacentHTML('beforeend', this._templatesListFormat(template));
    })
    if (!this.templatesTarget.innerHTML) {
      this.templatesTarget.insertAdjacentHTML('beforeend', " ");
    }
  }

  // テンプレートフォームを初期化
  _templateFormNew() {
    this.application
      .getControllerForElementAndIdentifier(this._templateForm(), 'unedit-scout-template-form')
      .initializeTemplateValues()
  }
}
