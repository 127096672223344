import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'container',
    'scouttype',
    'brandid',
    'storeid',
  ]

  static values = {
    scouttype: String,
    brandid: String,
    storeid: String,
  };

  connect() {
    console.log("scout_history_form_controller connect");
    const { brandidTarget, storeidTarget } = this;

    if (this.scouttypeValue == 'company') {
      brandidTarget.disabled = true;
      brandidTarget.classList.add('id-disabled');
      brandidTarget.value = '';
      storeidTarget.disabled = true;
      storeidTarget.classList.add('id-disabled');
      storeidTarget.value = '';
    } else if (this.scouttypeValue == 'brand') {
      brandidTarget.disabled = false;
      brandidTarget.classList.remove('id-disabled');
      brandidTarget.classList.add('js-no-search');
      storeidTarget.disabled = true;
      storeidTarget.classList.add('id-disabled');
      storeidTarget.value = '';
    } else if (this.scouttypeValue == 'store') {
      brandidTarget.disabled = false;
      brandidTarget.classList.remove('id-disabled');
      brandidTarget.classList.remove('js-no-search');
      storeidTarget.disabled = false;
      storeidTarget.classList.remove('id-disabled');
    } else {
      brandidTarget.disabled = true;
      brandidTarget.classList.add('id-disabled');
      brandidTarget.value = '';
      storeidTarget.disabled = true;
      storeidTarget.classList.add('id-disabled');
      storeidTarget.value = '';
    }
  }

  scouttypeValueChanged() {
    this.connect();
  }

  changeScoutType(e) {
    console.log(`changeScoutType: ${e.target.value}`);
    $(this.containerTarget).attr('data-scout-history-form-scouttype-value', e.target.value);
  }

  changeBrandId(e) {
    console.log(`changeBrandId: ${e.target.value}`);
    if (this.scouttypeValue == 'store') {
      this.storeidTarget.value = '';
    }
  }

}
