import {Controller} from "@hotwired/stimulus"

let compamnyInfoCache = {}
function getCompanyInfo(company_id) {
  if (!company_id) {
    return Promise.reject(new Error('company_id is required'))
  }
  const ENDPOINT_URL = `/api/v1/companies/${company_id}.json`
  if (compamnyInfoCache[company_id]) {
    return Promise.resolve(compamnyInfoCache[company_id])
  }
  return fetch(ENDPOINT_URL)
    .then((res) => res.json())
    .then((json) => {
      if (json.status == 'success') {
        compamnyInfoCache[company_id] = {
          logoimage: json['company_logo_url'],
          title: json['name'],
          subtitle: null,
        }
        return compamnyInfoCache[company_id]
      } else {
        throw new Error(json)
      }
    });
}

let brandInfoCache = {}
export function getBrandInfo(brand_id) {
  if (!brand_id) {
    return Promise.reject(new Error('brand_id is required'))
  }
  if (brandInfoCache[brand_id]) {
    return Promise.resolve(brandInfoCache[brand_id])
  }

  const ENDPOINT_URL = `/api/v1/brands/${brand_id}.json`
  return fetch(ENDPOINT_URL)
    .then((res) => res.json())
    .then((json) => {
      console.log(json)
      if (json.status == 'success') {
        brandInfoCache[brand_id] = {
          logoimage: json['brand_logo_url'],
          title: json['brand_name'],
          subtitle: json['genre_name'],
        }
        return brandInfoCache[brand_id]
      } else {
        throw new Error(json)
      }
    });
}


let storeInfoCache = {}
export function getStoreInfo(store_id) {
  if (!store_id) {
    return Promise.reject(new Error('store_id is required'))
  }
  const ENDPOINT_URL = `/api/v1/stores/${store_id}.json`
  if (storeInfoCache[store_id]) {
    return Promise.resolve(storeInfoCache[store_id])
  }

  return fetch(ENDPOINT_URL)
    .then((res) => res.json())
    .then((json) => {
      console.log(json)
      if (json.status == 'success') {
        const subtitle = (() => {
          if (json['brand_name'] == json['name']) {
            return json['genre_name']
          } else {
            return `${json['name']} / ${json['genre_name']}`
          }
        })()
        storeInfoCache[store_id] = {
          logoimage: json['brand_logo_url'],
          title: json['brand_name'],
          subtitle
        }
        return storeInfoCache[store_id]
      } else {
        throw new Error(json)
      }
    });
}

export default class extends Controller {
  static targets = [
    'container',
    'logoimage',
    'titleMessage',
    'subtitle',
    'sender',
    'title',
    'content',
  ];

  static values = {
    scouttype: String,
    companyid: String,
    brandid: String,
    storeid: String,
  };

  connect() {
    const company_id = this.companyidValue
    getCompanyInfo(company_id).then((info) => {
      this._setInfo(info)
    });
  }

  scouttypeValueChanged() {
    const company_id = this.companyidValue
    const brand_id = this.brandidValue
    const store_id = this.storeidValue
    // console.log(this.scouttypeValue, brand_id)
    if (this.scouttypeValue == 'company') {
      getCompanyInfo(company_id).then((info) => this._setInfo(info));
    } else if (this.scouttypeValue == 'brand') {
      if (brand_id) {
        getBrandInfo(brand_id).then((info) => this._setInfo(info));
      } else {
        getCompanyInfo(company_id).then((info) => this._setInfo(info))
      }
    } else if (this.scouttypeValue == 'store') {
      if (store_id) {
        getStoreInfo(store_id).then((info) => this._setInfo(info));
      } else {
        getCompanyInfo(company_id).then((info) => this._setInfo(info));
      }
    } else {
      this._setInfo({
        logoimage: '',
        title: '',
        subtitle: '',
      })
    }
  }

  brandidValueChanged() {
    const company_id = this.companyidValue
    const brand_id = this.brandidValue
    if (brand_id) {
      getBrandInfo(brand_id).then((info) => this._setInfo(info))
    } else {
      getCompanyInfo(company_id).then((info) => this._setInfo(info))
    }
  }

  storeidValueChanged() {
    const company_id = this.companyidValue
    const store_id = this.storeidValue
    if (store_id) {
      getStoreInfo(store_id).then((info) => this._setInfo(info))
    } else {
      getCompanyInfo(company_id).then((info) => this._setInfo(info))
    }
  }

  _setInfo(info) {
    this.logoimageTarget.src = info['logoimage']
    this.titleTarget.innerHTML = info['title']
    this.subtitleTarget.innerHTML = info['subtitle']
  }

  // 送信する値を変更する
  onChangeSender(e) {
    const id = this.containerTarget.dataset.id
    const value = e.target.value
    console.log(id, value)
    const $scout = $(`.js-scout[data-id="${id}"]`)
    e.preventDefault()
    $scout.attr('data-scout-form-container-sender-value', value)
  }

  // 送信する値を変更する
  onChangeTitle(e) {
    const id = this.containerTarget.dataset.id
    const value = e.target.value
    console.log(id, value)
    const $scout = $(`.js-scout[data-id="${id}"]`)
    $scout.attr('data-scout-form-container-title-value', value)
  }

  // 送信する値を変更する
  onChangeContent(e) {
    const id = this.containerTarget.dataset.id
    const value = e.target.value
    console.log(id, value)
    const $scout = $(`.js-scout[data-id="${id}"]`)
    $scout.attr('data-scout-form-container-content-value', value)
  }

  setInitialValue(scouttype, sender, title, content, companyid, brandid, storeid) {
    const id = this.containerTarget.dataset.id
    const $scout = $(`.js-scout[data-id="${id}"]`)
    $scout.attr('data-scout-form-container-sender-value', sender)
    this.senderTarget.value = sender
    $scout.attr('data-scout-form-container-title-value', title)
    this.titleMessageTarget.value = title
    $scout.attr('data-scout-form-container-content-value', content)
    this.contentTarget.value = content

    this.companyidValue = companyid
    this.brandidValue = brandid
    this.storeidValue = storeid
    this.scouttypeValue = scouttype

    const company_id = this.companyidValue
    const brand_id = this.brandidValue
    const store_id = this.storeidValue
    if (this.scouttypeValue == 'company') {
      getCompanyInfo(company_id).then((info) => this._setInfo(info));
    } else if (this.scouttypeValue == 'brand') {
      if (brand_id) {
        getBrandInfo(brand_id).then((info) => this._setInfo(info));
      } else {
        getCompanyInfo(company_id).then((info) => this._setInfo(info))
      }
    } else if (this.scouttypeValue == 'store') {
      if (store_id) {
        getStoreInfo(store_id).then((info) => this._setInfo(info));
      } else {
        getCompanyInfo(company_id).then((info) => this._setInfo(info));
      }
    } else {
      this._setInfo({
        logoimage: '',
        title: '',
        subtitle: '',
      })
    }
  }
}
