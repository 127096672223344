import {Controller} from "@hotwired/stimulus"
import { getCsrfToken } from "./lib/utils"

export default class extends Controller {
    static targets = [
        'templates',
        'template',
    ]


    static values = {
        id: String,
        templates: Array,
    }

    // 最初の一回だけ呼ばれる
    connect() {
        this.fetchTemplate()
    }

    // 「新規作成」が押された時
    handleOnTemplateNew() {
        this._templateFormNew()
    }

    // 検索キーワードが入力された時
    handleOnTemplateSearch(event) {
        const value = event.target.value

        // otherwise, show only matching
        this._renderTemplateList(this.templatesValue.filter(({template_title}) => template_title.includes(value)))
    }

    // テンプレートが選択された時
    handleOnTemplateSelect(event) {
        event.preventDefault()
        const target = event.target.closest('button')
        const id = target.dataset["id"]
        const template = this.templatesValue.find(template => String(template.id) === id)
        this._toScoutTemplateForm(template)
    }

    // テンプレートが削除された時
    handleOnTemplateDelete(event) {
        event.preventDefault()
        const id = event.target.dataset.id

        if (confirm(`"${event.target.dataset.name}" を削除してもよろしいですか？`)) {
            this._deleteTemplate(id)
        }
    }

    // scout-template-form からも呼ばれる
    fetchTemplate() {
        const ENDPOINT_URL = `/manage/scouts/templates/`
        const requestBody = {
            method: "GET",
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': getCsrfToken()
            },
        }
        return fetch(ENDPOINT_URL, requestBody)
            .then((res) => res.json())
            .then((json) => {
                this.templatesValue = json
                this._renderTemplateList(this.templatesValue)
            });
    }

    // 削除
    _deleteTemplate(id) {
        const ENDPOINT_URL = `/manage/scouts/templates/${id}`
        const requestBody = {
            method: "DELETE",
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': getCsrfToken()
            },
        }
        return fetch(ENDPOINT_URL, requestBody)
            .then((res) => res.json())
            .then(() => {
                this._templateFormNew()
                this._renderTemplateList([])
                this.fetchTemplate()
            });
    }

    // テンプレート一覧の表示形式
    _templatesListFormat(template) {
        return `
                <li class="c-block-11__list-item" data-scout-template-modal-target="template" data-name="${template.template_title}">
                  <button type="button" class="c-block-11__list-btn" data-action="click->scout-template-modal#handleOnTemplateSelect" data-id="${template.id}">
                    <span>${template.template_title}</span>
                  </button>
                  <button type="button" class="c-block-11__list-delete" data-action="click->scout-template-modal#handleOnTemplateDelete" data-id="${template.id}" data-name="${template.template_title}"></button>
                </li>`
    }

    // テンプレートフォームに値をセット
    _toScoutTemplateForm(template) {
        let brandId = template.scout_template_brand ? template.scout_template_brand.brand_id : null
        template.store ? brandId = template.store.brand_id : null
        const storeId = template.scout_template_store ? template.scout_template_store.store_id : null
        const $templateForm = this._templateForm()

        // テンプレートフォームに値をセット
        this.application.getControllerForElementAndIdentifier($templateForm, 'scout-template-form').setTemplateValues(
            template.id,
            template.template_type,
            template.template_title,
            brandId,
            storeId,
            template.sender,
            template.title,
            template.content
        )
    }

    // テンプレートフォームのDOMを取得
    _templateForm() {
        return document.getElementById(`scout_template_form`)
    }

    // テンプレート一覧を描画
    _renderTemplateList(templates) {
        this.templatesTarget.innerHTML = ""
        templates.map(template => {
            this.templatesTarget.insertAdjacentHTML('beforeend', this._templatesListFormat(template));
        })
        if (!this.templatesTarget.innerHTML) {
            this.templatesTarget.insertAdjacentHTML('beforeend', " ");
        }
    }

    // テンプレートフォームを初期化
    _templateFormNew() {
        this.application
            .getControllerForElementAndIdentifier(this._templateForm(), 'scout-template-form')
            .initializeTemplateValues()
    }
}
